export enum FeatureFlag {
  BOOMCARDS_SITESHARE = 'boomcardsSiteShare',
  BOOMCARDS_IFRAME = 'boomcardsIframe',
  INTEGRATED_LIBRARY = 'integratedLibrary',
  INACTIVITY_AUTOLOGOUT_BYPASS = 'inactivityAutologoutBypass',
  VIEW_AI_SUMMARY = 'viewAISummary',
  WJV_ASSESSMENTS = 'wjvAssessments',
  NEW_PARTICIPANTS_PANEL = 'newParticipantsPanel',
  NEW_LIBRARY_FAVORITE_ACTION = 'newLibraryFavoriteAction',
  NEW_ROOM_CARD_GAMES = 'newRoomCardGames',
  ACTIVITY_HISTORY = 'activityHistory',
  NEW_LIBRARY_ACTIVITY_FEEDBACK = 'newLibraryActivityFeedback',
  LIBRARY_ACTIVITY_COLLECTIONS = 'libraryActivityCollections',
  CLIENT_MOOD_PROMPT = 'clientMoodPrompt',
}
